import React from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';

import {
  HOME_PAGE,
  LISTING_POSTS_PAGE,
  PRIVACY_PAGE,
  ABOUT,
  CONTACT
} from 'settings/constant';

const FooterMenu = () => {
  return (
    <Menu>
      <Menu.Item key="0">
        <NavLink to={`${HOME_PAGE}`}>Home</NavLink>
      </Menu.Item>
      <Menu.Item key="1">
        <NavLink to={`${LISTING_POSTS_PAGE}`}>Oferty</NavLink>
      </Menu.Item>
      <Menu.Item key="2">
        <NavLink to={`${ABOUT}`}>O Mnie</NavLink>
      </Menu.Item>
      <Menu.Item key="3">
        <NavLink to={`${CONTACT}`}>Kontakt</NavLink>
      </Menu.Item>
      <Menu.Item key="4">
        <NavLink to={`${PRIVACY_PAGE}`}>Regulamin</NavLink>
      </Menu.Item>
    </Menu>
  );
};

export default FooterMenu;
