import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

export const AuthContext = React.createContext();

const AuthProvider = (props) => {
  const { isAuthenticated } = useAuth0();
  let history = useHistory();
  const [loggedIn, setLoggedIn] = useState(isAuthenticated);
  const [user, setUser] = useState({});

  useEffect(() => {
    setLoggedIn(isAuthenticated)
  },[isAuthenticated])

  const signIn = (params) => {
    console.log(params, 'sign in form Props');
    setLoggedIn(true);
    history.push(`/`);
  };

  const signUp = (params) => {
    console.log(params, 'sign up form Props');
    setLoggedIn(true);
    history.push(`/`);
  };

  const logOut = () => {
    setUser(null);
    setLoggedIn(false);
  };

  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        logOut,
        signIn,
        signUp,
        user,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
