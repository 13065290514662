import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';
import { AuthContext } from 'context/AuthProvider';

import {
  HOME_PAGE,
  LISTING_POSTS_PAGE,
  ABOUT,
  CONTACT,
  ADD_HOTEL_PAGE,
  ADMIN
} from 'settings/constant';

const MainMenu = ({ className }) => {
  const { loggedIn } = useContext(AuthContext);
  return (
    <Menu className={className}>
      <Menu.Item key="0">
        <NavLink exact to={`${HOME_PAGE}`}>
          Home
        </NavLink>
      </Menu.Item>

      {loggedIn ? (
        <>
        <Menu.Item key="1">
          <NavLink to={ADD_HOTEL_PAGE}>Dodaj Ofertę</NavLink>
        </Menu.Item>
        <Menu.Item key="2">
          <NavLink to={ADMIN}>Admin</NavLink>
        </Menu.Item>
        </>
      ) : (
        <>
          <Menu.Item key="3">
            <NavLink to={`${LISTING_POSTS_PAGE}`}>Oferty</NavLink>
          </Menu.Item>
          <Menu.Item key="4">
            <NavLink to={`${ABOUT}`}>O Mnie</NavLink>
          </Menu.Item>
          <Menu.Item key="5">
            <NavLink to={`${CONTACT}`}>Kontakt</NavLink>
          </Menu.Item>
        </>
      )
      }
    </Menu>
  );
};

export default MainMenu;
