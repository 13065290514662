import React from 'react';
import PropTypes from 'prop-types';
import FooterWrapper, {
  MenuWrapper,
  CopyrightArea,
  SecondaryFooter,
} from './Footer.style';

const Footer = ({ logo, menu, copyright, className, path }) => {
  return (
    <>
      <FooterWrapper id="footer" className={className} >
        {logo && logo}
        {menu && <MenuWrapper>{menu}</MenuWrapper>}
        {copyright && <CopyrightArea>{copyright}</CopyrightArea>}
        <p>developed by <a href="https://paranoidal97.netlify.app/">Łukasz Rams</a></p>
      </FooterWrapper>
      {!!path && <SecondaryFooter />}
    </>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  logo: PropTypes.element,
  menu: PropTypes.element,
  copyright: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default Footer;
