import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from 'themes/default.theme';
import GlobalStyles from 'themes/global.style';
import AuthProvider from 'context/AuthProvider';
import Routes from './router';
import { Auth0Provider } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import MessengerCustomerChat from 'react-messenger-customer-chat';


const Auth0ProviderWithHistory = ({ children }) => {

  const history = useHistory();
  const onRedirectCallback = (appState) => {

    history.push(appState?.returnTo || window.location.pathname);
  };

  
     
  return (
    <Auth0Provider
      domain="dev-ztt99fth.eu.auth0.com"
      clientId="Fxgy3kUUGg41zq7vNK1TOHTXYOJzOHtY"
      redirectUri={window.location.origin}
    >
      {children}
    </Auth0Provider>
  );
};

const App = () => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyles />
      <BrowserRouter>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </BrowserRouter>
    </>
  </ThemeProvider>
);

ReactDOM.render(
  <Auth0ProviderWithHistory>
      <MessengerCustomerChat
      pageId="109208041341324"
      appId="1909167109230459"
    />
    <App />
  </Auth0ProviderWithHistory>
, document.getElementById('root'));
